import * as OutlineIcons from '@heroicons/react/24/outline';
import * as SolidIcons from '@heroicons/react/24/solid';

export type IconName = keyof typeof iconMap;
export type IconType = 'solid' | 'outline';
export type Icon = {
  name: IconName;
  type: IconType;
};
export type IconOptions = Icon & {
  position: 'prefix' | 'suffix';
  size: number;
};

/*
 * 必要なアイコンを適宜 `pnpm run icon {iconname}` で追加する ref: https://heroicons.com/outline
 */
export const iconMap = {
  'arrow-left-start-on-rectangle': {
    outline: OutlineIcons.ArrowLeftStartOnRectangleIcon,
    solid: SolidIcons.ArrowLeftStartOnRectangleIcon,
  },
  'arrow-right': {
    outline: OutlineIcons.ArrowRightIcon,
    solid: SolidIcons.ArrowRightIcon,
  },
  'arrow-top-right-on-square': {
    outline: OutlineIcons.ArrowTopRightOnSquareIcon,
    solid: SolidIcons.ArrowTopRightOnSquareIcon,
  },
  'arrow-up-tray': {
    outline: OutlineIcons.ArrowUpTrayIcon,
    solid: SolidIcons.ArrowUpTrayIcon,
  },
  'arrow-uturn-left': {
    outline: OutlineIcons.ArrowUturnLeftIcon,
    solid: SolidIcons.ArrowUturnLeftIcon,
  },
  'bars-3': {
    outline: OutlineIcons.Bars3Icon,
    solid: SolidIcons.Bars3Icon,
  },
  'chat-bubble-bottom-center-text': {
    outline: OutlineIcons.ChatBubbleBottomCenterTextIcon,
    solid: SolidIcons.ChatBubbleBottomCenterTextIcon,
  },
  check: {
    outline: OutlineIcons.CheckIcon,
    solid: SolidIcons.CheckIcon,
  },
  'check-circle': {
    outline: OutlineIcons.CheckCircleIcon,
    solid: SolidIcons.CheckCircleIcon,
  },
  'chevron-down': {
    outline: OutlineIcons.ChevronDownIcon,
    solid: SolidIcons.ChevronDownIcon,
  },
  'chevron-right': {
    outline: OutlineIcons.ChevronRightIcon,
    solid: SolidIcons.ChevronRightIcon,
  },
  'chevron-up': {
    outline: OutlineIcons.ChevronUpIcon,
    solid: SolidIcons.ChevronUpIcon,
  },
  document: {
    outline: OutlineIcons.DocumentIcon,
    solid: SolidIcons.DocumentIcon,
  },
  'exclamation-circle': {
    outline: OutlineIcons.ExclamationCircleIcon,
    solid: SolidIcons.ExclamationCircleIcon,
  },
  folder: {
    outline: OutlineIcons.FolderIcon,
    solid: SolidIcons.FolderIcon,
  },
  home: {
    outline: OutlineIcons.HomeIcon,
    solid: SolidIcons.HomeIcon,
  },
  'magnifying-glass': {
    outline: OutlineIcons.MagnifyingGlassIcon,
    solid: SolidIcons.MagnifyingGlassIcon,
  },
  minus: {
    outline: OutlineIcons.MinusIcon,
    solid: SolidIcons.MinusIcon,
  },
  plus: {
    outline: OutlineIcons.PlusIcon,
    solid: SolidIcons.PlusIcon,
  },
  'question-mark-circle': {
    outline: OutlineIcons.QuestionMarkCircleIcon,
    solid: SolidIcons.QuestionMarkCircleIcon,
  },
  slash: {
    outline: OutlineIcons.SlashIcon,
    solid: SolidIcons.SlashIcon,
  },
  'x-circle': {
    outline: OutlineIcons.XCircleIcon,
    solid: SolidIcons.XCircleIcon,
  },
  'x-mark': {
    outline: OutlineIcons.XMarkIcon,
    solid: SolidIcons.XMarkIcon,
  },
};
