import type { CSSProperties } from 'react';
import type React from 'react';
import { twMerge } from 'tailwindMerge.config';
import { iconMap } from '~/utils/icon';
import type { IconName, IconType } from '~/utils/icon';

interface IconProps {
  iconName: IconName;
  type?: IconType;
  className?: string;
  style?: CSSProperties;
}

export const PtnIcon: React.FC<IconProps> = ({
  iconName,
  type = 'solid',
  className = '',
  ...props
}) => {
  const IconComponent = iconMap[iconName][type];

  if (!IconComponent) {
    // 不正なアイコン名が渡された場合に何も表示しない
    return null;
  }

  return (
    <IconComponent className={twMerge('flex-shrink-0', className)} {...props} />
  );
};
